import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import HiddenInput from '../src/components/HiddenInput/HiddenInput';
import BranchesInput from '../src/components/BranchesInput/BranchesInput';
import OrganizationInput from '../src/components/OrganizationInput/OrganizationInput';
import Input from '../src/components/Input/Input';

const UserAccessInput = props => {
  const {
    existingRootOrganization,
    existingOrganization,
    isInternalUser,
    onChange,
    required,
    showRequiredIndicator
  } = props;

  // const [rootOrganization, setRootOrganization] = useState(rootOrganization);
  const [organizationId, setOrganizationId] = useState(
    existingRootOrganization
      ? existingRootOrganization.id
      : existingOrganization
      ? existingOrganization.id
      : null
  );

  const [rootOrganization, setRootOrganization] = useState(
    existingRootOrganization
  );

  const [hasChildren, setHasChildren] = useState(false);

  const [parentLevelPermission, setParentLevelPermission] = useState(
    'useParent'
  );

  const handleParentOrganizationIdChange = id => {
    setOrganizationId(id);
    setParentLevelPermission('useParent');
    fetchOrganizationBranches(id);

    if (onChange) {
      onChange(id);
    }
  };

  const handleChildOrganizationIdChange = e => {
    setOrganizationId(e.target.value);

    if (onChange) {
      onChange(e.target.value);
    }
  };

  const fetchOrganizationBranches = id => {
    fetch(`/organizations/${id}/branches`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    })
      .then(results => {
        if (results.status === 204) {
          return false;
        }
        return results.json();
      })
      .then(data => {
        if (data) {
          setRootOrganization(data);
          setHasChildren(true);
        } else {
          setHasChildren(false);
        }
      });
  };

  const handlePermissionSelection = e => {
    if (e.target.value === 'useParent') {
      setParentLevelPermission('useParent');
      setOrganizationId(rootOrganization.id);
      if (onChange) {
        onChange(rootOrganization.id);
      }
    } else {
      setParentLevelPermission('useChild');
    }
  };

  useEffect(() => {
    if (rootOrganization) {
      if (existingOrganization?.id !== existingRootOrganization?.id) {
        setParentLevelPermission('useChild');
      }
      fetchOrganizationBranches(organizationId);
    }
  }, []);

  return (
    <div>
      <div>
        {isInternalUser ? (
          <OrganizationInput
            id="organization-id"
            label="Organization"
            value={rootOrganization ? rootOrganization.name : ''}
            onSelect={handleParentOrganizationIdChange}
            required={required}
            showRequiredIndicator={showRequiredIndicator}
          />
        ) : (
          <Input
            id="organization-id"
            name="organization"
            label="Organization"
            value={rootOrganization.name}
            disabled={true}
          />
        )}
      </div>
      {hasChildren && (
        <div className="bg-gray-50 rounded-b">
          <div className="p-6">
            <div>
              <label style={{ fontWeight: 'normal' }}>
                <input
                  type="radio"
                  name="permission"
                  value="useParent"
                  checked={parentLevelPermission === 'useParent' ? true : false}
                  onChange={handlePermissionSelection}
                />
                &nbsp; Allow user access to all of {rootOrganization.name}.
              </label>
              <br />
              <label style={{ fontWeight: 'normal' }}>
                <input
                  type="radio"
                  name="permission"
                  value="useChild"
                  checked={parentLevelPermission === 'useChild' ? true : false}
                  onChange={handlePermissionSelection}
                />
                &nbsp; Select which group or branch this user should belong to.
              </label>
            </div>
            {parentLevelPermission === 'useChild' && (
              <BranchesInput
                id="organization-id"
                name="branches"
                tree={rootOrganization}
                className="mt-6"
                label="Allow user to access:"
                value={
                  existingOrganization ? existingOrganization.id.toString() : ''
                }
                onChange={handleChildOrganizationIdChange}
                required={required}
                showRequiredIndicator={showRequiredIndicator}
              />
            )}
          </div>
        </div>
      )}
      <HiddenInput
        name="organization_id"
        value={organizationId ? organizationId.toString() : ''}
      />
      <HiddenInput name="from_react_component" value="true" />
    </div>
  );
};

UserAccessInput.defaultProps = {
  required: false,
  showRequiredIndicator: true
};

UserAccessInput.propTypes = {
  existingRootOrganization: PropTypes.object,
  existingOrganization: PropTypes.object,
  isInternalUser: PropTypes.bool,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  showRequiredIndicator: PropTypes.bool
};

export default UserAccessInput;
