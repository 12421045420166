import React, { useState } from 'react';
import Checkbox from '../../../components/Checkbox/Checkbox';
import PropTypes from 'prop-types';
import Table from '../../../components/Table/Table';
import TableRow from '../../../components/Table/TableRow';
import TableCell from '../../../components/Table/TableCell';
import TableHeaderCell from '../../../components/Table/TableHeaderCell';
import Button from '../../../components/Button/Button';
import { Inertia } from '@inertiajs/inertia';

const Markets = ({
  bundleAlgorithm,
  markets,
  token,
  enableMarketsPath,
  bundleAlgorithmsPath
}) => {
  const [allMarkets, setAllMarkets] = useState(
    Object.fromEntries(markets.map(market => [market.id, market]))
  );

  const handleMarketsToEnableChange = market => {
    const clickedMarket = allMarkets[market.id];
    if (clickedMarket.isEnabled) {
      setAllMarkets(prev => {
        return {
          ...prev,
          [market.id]: {
            ...clickedMarket,
            isEnabled: false
          }
        };
      });
    } else {
      setAllMarkets(prev => {
        return {
          ...prev,
          [market.id]: {
            ...clickedMarket,
            isEnabled: true
          }
        };
      });
    }
  };

  const handleSubmit = () => {
    const marketsToEnable = Object.values(allMarkets).filter(m => m.isEnabled);

    Inertia.put(
      enableMarketsPath,
      { enabled_markets: marketsToEnable },
      {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': token
        }
      }
    );
  };

  return (
    <div>
      <div>
        <h1>{bundleAlgorithm.algorithm}</h1>
        <p>Enable markets below for {bundleAlgorithm.algorithm}</p>
      </div>

      <div>
        <Button style="primary" onClick={handleSubmit}>
          Update Markets
        </Button>
        <Button style="muted" customClass={'ml-4'} href={bundleAlgorithmsPath}>
          Cancel
        </Button>
      </div>

      <Table
        thead={
          <TableRow>
            <TableHeaderCell>Name</TableHeaderCell>
            <TableHeaderCell>Enabled</TableHeaderCell>
          </TableRow>
        }
      >
        {Object.values(allMarkets).map(market => {
          return (
            <TableRow key={market.id}>
              <TableCell>
                <label htmlFor={market.id}>{market.name}</label>
              </TableCell>

              <TableCell>
                <Checkbox
                  checked={market.isEnabled}
                  id={market.id}
                  label={<span className="sr-only">{market.name}</span>}
                  onChange={() => handleMarketsToEnableChange(market)}
                  testId={market.name.replace(/\s+/g, '')}
                />
              </TableCell>
            </TableRow>
          );
        })}
      </Table>
    </div>
  );
};

Markets.propTypes = {
  bundleAlgorithm: PropTypes.object,
  markets: PropTypes.arrayOf(PropTypes.object),
  enableMarketsPath: PropTypes.string,
  bundleAlgorithmsPath: PropTypes.string,
  token: PropTypes.string
};

export default Markets;
