import React from 'react';
import PropTypes from 'prop-types';

const InitialAvatar = ({ givenName, familyName }) => {
  function getInitials() {
    return `${givenName[0]}${familyName[0]}`;
  }

  return (
    <div className="flex justify-center items-center rounded-full w-11 h-11 mr-8 bg-indigo-500">
      <span className="text-white text-sm">{getInitials()}</span>
    </div>
  );
};

InitialAvatar.defaultProps = {
  givenName: ' ',
  familyName: ' '
};

InitialAvatar.propTypes = {
  givenName: PropTypes.string,
  familyName: PropTypes.string
};

export default InitialAvatar;
