import React from 'react';
import PropTypes from 'prop-types';
import List from './List';
import EmptyStateWithMessage from '../../../components/EmptyStateWithMessage/EmptyStateWithMessage';
import Button from '../../../components/Button/Button';

const Index = props => {
  const { bundle_algorithms, can_manage_bundle_algorithms } = props;

  return (
    <>
      <div className="page-header">
        <h1>Bundle Algorithms</h1>
        <div className="row">
          <div className="col-sm-12 text-muted">
            New bundle algorithms will be enabled in the selected markets by
            default
          </div>
        </div>
      </div>
      {can_manage_bundle_algorithms ? (
        <div>
          <Button style="primary" href="/admin/bundle_algorithms/new">
            Create Bundle Algorithm
          </Button>
        </div>
      ) : (
        <> </>
      )}
      {bundle_algorithms.list.length === 0 ? (
        <EmptyStateWithMessage
          message="No active bundle algorithms"
          subMessage="Bundle algorithms you create will show up here."
        />
      ) : (
        <List list={bundle_algorithms.list} pages={bundle_algorithms.pages} />
      )}
    </>
  );
};

Index.propTypes = {
  bundle_algorithms: PropTypes.objectOf(PropTypes.any),
  can_manage_bundle_algorithms: PropTypes.bool
};

export default Index;
