import React from 'react';
import { Link } from '@inertiajs/inertia-react';
import PropTypes from 'prop-types';

function getCurrentPage() {
  var urlParams = new URLSearchParams(window.location.search);
  var currentPage = urlParams.get('page');
  if (currentPage) {
    return parseInt(currentPage);
  } else {
    return 1;
  }
}

function buildPageList(pagecount) {
  var liArray = [];
  for (let i = getCurrentPage(); i < getCurrentPage() + 24; i++) {
    if (i <= pagecount) {
      liArray.push(
        <li key={i} className={i === getCurrentPage() ? 'active' : ''}>
          <Link href={`${window.location.pathname}?page=${i}`}>{i}</Link>
        </li>
      );
    }
  }
  return liArray;
}

const Pagination = props => {
  const { pagecount } = props;

  return (
    <div>
      <ul className="pagination">
        <li className={getCurrentPage() <= 1 ? 'disabled' : ''}>
          {getCurrentPage() <= 1 ? (
            <span>‹ Prev</span>
          ) : (
            <Link
              href={`${window.location.pathname}?page=${getCurrentPage() - 1}`}
            >
              ‹ Prev
            </Link>
          )}
        </li>
      </ul>
      <ul className="pagination">{buildPageList(pagecount)}</ul>
      <ul className="pagination">
        <li className={getCurrentPage() >= pagecount ? 'disabled' : ''}>
          {getCurrentPage() >= pagecount ? (
            <span>Next</span>
          ) : (
            <Link
              href={`${window.location.pathname}?page=${getCurrentPage() + 1}`}
            >
              Next
            </Link>
          )}
        </li>
      </ul>
    </div>
  );
};

Pagination.propTypes = {
  pagecount: PropTypes.number
};

export default Pagination;
