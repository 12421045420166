import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import filter from 'lodash/filter';
import eq from 'lodash/eq';
import successIcon from '../../assets/success.svg';
import warningIcon from '../../assets/warning.svg';
import errorIcon from '../../assets/error.svg';
import closeIcon from '../../assets/close.svg';

const TOAST_ICON = {
  success: successIcon,
  warning: warningIcon,
  error: errorIcon
};

const Toast = toast => {
  useEffect(() => {
    setTimeout(() => {
      toast?.close(toast.id);
    }, 20000);
  }, []);

  return (
    <div className="p-8 border border-black shadow-xl rounded-[3px] w-[370px] flex bg-white relative mb-8 min-h-[105px]">
      {!isNil(toast.type) && (
        <div className="w-[12%]">
          <div>
            <img
              src={TOAST_ICON[toast.type]}
              className="w-10"
              alt="toast type"
            />
          </div>
        </div>
      )}
      <div className="pr-7">
        {!isNil(toast.message?.title) && (
          <h6 className="text-navyDark font-semibold text-[16px] leading-6 mb-2 p0 max-width-[25ch]">
            {toast.message?.title}
          </h6>
        )}
        {!isNil(toast.message?.content) && (
          <p className="text-navyDark text-[14px] leading-5 max-width-[30ch]">
            {toast?.message?.content}
          </p>
        )}
        {!isNil(toast.link) && (
          <a
            href={toast.link.url}
            className="text-[#2467F6] font-semibold text-[14px] leading-5"
          >
            {toast.link.text}
          </a>
        )}
      </div>
      <button
        onClick={() => toast?.close(toast.id)}
        className="absolute top-[16px] right-[16px] bg-transparent border-0 text-2xl font-thin"
      >
        <img src={closeIcon} alt="close" />
      </button>
    </div>
  );
};

const Toasts = ({ toasts, onClose }) => {
  const handleClose = toastId => {
    onClose(filter(toasts, toast => !eq(toast.id, toastId)));
  };

  return (
    <div className="fixed top-8 right-8 z-[1000]">
      {map(toasts, toast => (
        <Toast key={toast.id} {...toast} close={handleClose} />
      ))}
    </div>
  );
};

Toasts.propTypes = {
  toasts: PropTypes.array,
  onClose: PropTypes.func
};

export default Toasts;
