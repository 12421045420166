import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
/*
  milliseonds = initial timer value (300000 will start a time for 5 minutes)
  showHours = show hour field. By default we show the minutes and seconds field
  onTimeReachedZero = a callback that's triggered when remaining time reaches 0

  note: the onTimeReachedZero callback can be off by 1000 milliseconds. We update the remaining time every second,
    so for example if you initialize a timer for 800 milliseconds, the callback will trigger after 1000 milliseconds (200 milliseconds late)
*/
const CountdownTimer = props => {
  const {
    className,
    milliseconds,
    hoursLeadingZeros = 2,
    minutesLeadingZeros = 2,
    secondsLeadingZeros = 2,
    showHours,
    onTimeReachedZero
  } = props;

  const [msRemaining, setMsRemaining] = useState(milliseconds);

  const MS_HOURS = 3600000;
  const MS_MINUTES = 60000;
  const MS_SECONDS = 1000;

  const target_time = new Date(Date.now() + milliseconds);

  const calculateTimeComponents = () => {
    let ms = msRemaining;

    let hours = showHours ? 0 : null;
    let minutes = 0;
    let seconds = 0;

    if (showHours && ms >= MS_HOURS) {
      hours = Math.floor(ms / MS_HOURS);
      ms = ms - hours * MS_HOURS;
    }

    if (ms >= MS_MINUTES) {
      minutes = Math.floor(ms / MS_MINUTES);
      ms = ms - minutes * MS_MINUTES;
    }

    if (ms >= MS_SECONDS) {
      seconds = Math.floor(ms / MS_SECONDS);
    }

    return [hours, minutes, seconds];
  };

  const displayFormattedTime = () => {
    const timeComponents = calculateTimeComponents();

    var formattedTime = '';

    if (timeComponents[0] !== null) {
      formattedTime =
        formattedTime +
        timeComponents[0].toString().padStart(hoursLeadingZeros, '0') +
        ':';
    }

    if (timeComponents[1] !== null) {
      formattedTime =
        formattedTime +
        timeComponents[1].toString().padStart(minutesLeadingZeros, '0') +
        ':';
    }

    formattedTime =
      formattedTime +
      timeComponents[2].toString().padStart(secondsLeadingZeros, '0') +
      ':';

    formattedTime = formattedTime.replace(/:$/, '');

    return formattedTime;
  };

  const countDown = () => {
    const interval = setInterval(() => {
      const diff = target_time - Date.now();
      setMsRemaining(diff);

      if (diff <= 0) {
        onTimeReachedZero && onTimeReachedZero();
        clearInterval(interval);
      }
    }, 1000);
  };

  useEffect(() => {
    countDown();
  }, []);

  return <div className={className}>{displayFormattedTime()}</div>;
};

CountdownTimer.propTypes = {
  className: PropTypes.string,
  milliseconds: PropTypes.number,
  showHours: PropTypes.bool,
  hoursLeadingZeros: PropTypes.number,
  minutesLeadingZeros: PropTypes.number,
  secondsLeadingZeros: PropTypes.number,
  onTimeReachedZero: PropTypes.func
};

export default CountdownTimer;
