import React from 'react';
import PropTypes from 'prop-types';

const EmptyStateWithMessage = props => {
  const { message, subMessage } = props;

  return (
    <div className="h-80 p-2.5 bg-gray-50 flex flex-col items-center justify-center rounded">
      <div className="text-xl mb-2.5">{message}</div>
      <div className="text-xs">{subMessage}</div>
    </div>
  );
};

EmptyStateWithMessage.propTypes = {
  message: PropTypes.string,
  subMessage: PropTypes.string
};

export default EmptyStateWithMessage;
