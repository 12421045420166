import React from 'react';
import PropTypes from 'prop-types';
import Table from '../../../components/Table/Table';
import TableRow from '../../../components/Table/TableRow';
import TableCell from '../../../components/Table/TableCell';
import TableHeaderCell from '../../../components/Table/TableHeaderCell';

import Pagination from './Pagination';

const List = props => {
  const { list, pages } = props;

  return (
    <>
      <Pagination pagecount={pages} />

      <Table
        thead={
          <TableRow>
            <TableHeaderCell>Name</TableHeaderCell>
            <TableHeaderCell>Created By</TableHeaderCell>
            <TableHeaderCell>Created At</TableHeaderCell>
            <TableHeaderCell>Last Updated</TableHeaderCell>
            <TableHeaderCell>Auto-assign Drivers</TableHeaderCell>
            <TableHeaderCell>Markets</TableHeaderCell>
            <TableHeaderCell>Default</TableHeaderCell>
          </TableRow>
        }
      >
        {list &&
          list.map(bundle_algorithm => (
            <TableRow key={bundle_algorithm.id}>
              <TableCell>
                <a
                  href={`/admin/bundle_algorithms/${bundle_algorithm.id}/edit`}
                >
                  {bundle_algorithm.name}
                </a>
              </TableCell>
              <TableCell>{bundle_algorithm.created_by}</TableCell>
              <TableCell>{bundle_algorithm.created_at}</TableCell>
              <TableCell>
                <div>{bundle_algorithm.updated_at}</div>
              </TableCell>
              <TableCell>
                <div className="text-muted">
                  {bundle_algorithm.auto_assign_drivers}
                </div>
              </TableCell>
              <TableCell>
                <div className="text-muted">
                  <a
                    href={`/admin/bundle_algorithms/${bundle_algorithm.id}/markets`}
                  >
                    {bundle_algorithm.market_count}
                  </a>
                </div>
              </TableCell>
              <TableCell>
                <div className="text-muted">
                  {bundle_algorithm.default && 'Yes'}
                </div>
              </TableCell>
            </TableRow>
          ))}
      </Table>
    </>
  );
};

List.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
  pages: PropTypes.number
};

export default List;
