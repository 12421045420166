import React, { useState } from 'react';
import PropTypes from 'prop-types';
import GoogleMapWrapper from '../src/components/GoogleMap/GoogleMapWrapper';
import GoogleMap from '../src/components/GoogleMap/GoogleMap';
import Input from '../src/components/Input/Input';
import Button from '../src/components/Button/Button';
import { milesToKilometers } from '../src/utils';
import LocationInput from '../src/components/LocationInput/LocationInput';

const CourierServiceAreaMap = props => {
  const {
    center,
    googleApiKey,
    radiusMiles,
    authenticityToken,
    updateServiceAreaPath,
    service_area_id,
    serviceAreaName,
    deleteServiceAreaPath,
    activeCourier
  } = props;

  const MAXMILES = '500';

  const [newRadiusMiles, setNewRadiusMiles] = useState(radiusMiles);
  const [centerCoords, setCenterCoords] = useState(center);
  const [statusMessage, setStatusMessage] = useState('');
  const [newServiceAreaName, setNewServiceAreaName] = useState(serviceAreaName);

  const handleRadiusChanged = e => {
    setNewRadiusMiles(e.target.value);
  };

  const handleServiceAreaNameChanged = e => {
    setNewServiceAreaName(e.target.value);
  };

  const handleLocationChange = fields => {
    if (!fields) return;
    const new_center_coords = {
      lat: Number(fields.geo_lat),
      lng: Number(fields.geo_lng)
    };
    setCenterCoords(new_center_coords);
  };

  const handleDeletePressed = () => {
    fetch(deleteServiceAreaPath, {
      method: 'DELETE',
      headers: {
        'X-CSRF-Token': authenticityToken,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify({
        courier: {
          service_area_id
        }
      })
    }).then(response => {
      response.json().then(json => {
        const textColor = response.ok ? 'text-green' : 'text-red';

        setStatusMessage(
          <span className={`pl-2.5 italic ${textColor}`}>{json.message}</span>
        );
      });
    });
  };

  const handleUpdateServiceAreaClicked = () => {
    fetch(updateServiceAreaPath, {
      method: 'PUT',
      headers: {
        'X-CSRF-Token': authenticityToken,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify({
        courier: {
          service_area_radius_mi: newRadiusMiles,
          service_area_id,
          center_coords: centerCoords,
          service_area_name: newServiceAreaName
        }
      })
    }).then(response => {
      response.json().then(json => {
        const textColor = response.ok ? 'text-green' : 'text-red';

        setStatusMessage(
          <span className={`pl-2.5 italic ${textColor}`}>{json.message}</span>
        );
      });
    });
  };
  const uiAddress = centerCoords ?? center;

  return (
    <>
      <Input
        id="service_area_name"
        name="service_area_name"
        value={serviceAreaName}
        className={'mt-9 max-w-3xl'}
        placeholder="Name this service area"
        onChange={handleServiceAreaNameChanged}
        label={<span className="sr-only">Service Area Name</span>}
      />
      <GoogleMapWrapper className={'h-full mt-6'}>
        <GoogleMap
          center={{
            lat: uiAddress.lat,
            lng: uiAddress.lng,
            type: 'circle'
          }}
          showCenterPin={true}
          zoom={14}
          googleApiKey={googleApiKey}
          pins={[{ ...uiAddress, type: 'circle' }]}
          radiusKm={milesToKilometers(newRadiusMiles)}
        ></GoogleMap>
      </GoogleMapWrapper>
      <div className="pt-5 max-w-3xl">
        <LocationInput
          id="address"
          idPrefix="address"
          label="Where are you located?"
          name="address"
          namePrefix=""
          placeholder="Type your service location"
          inputName={<span className="sr-only">Service Area Location</span>}
          handleLocationChange={handleLocationChange}
          required={true}
          showRequiredIndicator={true}
          autocompleteOptions={{
            saved_locations: [],
            recent_locations: []
          }}
        />
        <Input
          id="service_area_miles"
          name="radius_miles"
          label="How many miles away from your location do you typically service?"
          value={radiusMiles}
          min="1"
          max={MAXMILES}
          onChange={handleRadiusChanged}
        />
        <div className="help-block -mt-2.5">{`We will only offer orders to you within this range. (max ${MAXMILES} miles)`}</div>
      </div>
      <div>
        {service_area_id === null || service_area_id === undefined ? (
          <Button
            style="bright"
            customClass="mt-5"
            onClick={handleUpdateServiceAreaClicked}
            disabled={!activeCourier}
          >
            Add New Location
          </Button>
        ) : (
          <>
            <Button
              style="primary"
              customClass="mt-5"
              onClick={handleUpdateServiceAreaClicked}
              disabled={!activeCourier}
            >
              Update Service Area
            </Button>
            <Button
              customClass="mt-5 ml-5 bg-red-400"
              onClick={handleDeletePressed}
              disabled={!activeCourier}
            >
              Delete
            </Button>
          </>
        )}
        <span className="pl-2.5 italic">{statusMessage}</span>
      </div>
    </>
  );
};

CourierServiceAreaMap.propTypes = {
  center: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number
  }),
  googleApiKey: PropTypes.string,
  radiusMiles: PropTypes.string,
  authenticityToken: PropTypes.string,
  updateServiceAreaPath: PropTypes.string,
  service_area_id: PropTypes.string,
  serviceAreaName: PropTypes.string,
  deleteServiceAreaPath: PropTypes.string,
  activeCourier: PropTypes.bool
};

export default CourierServiceAreaMap;
