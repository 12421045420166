/* eslint-disable no-console, jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */

import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';
import { importOrdersCSVTemplate } from '../../utils/csvTemplates';
import eq from 'lodash/eq';
import Modal from '../Modals/Modal';

const CustomCsvUploader = ({
  authenticityToken,
  id,
  onCsvSelect,
  onSuccess,
  onFail,
  onFetch,
  onLoading,
  path,
  promptText,
  showDownloadTemplate,
  useFileButton,
  onFileSelect,
  ...props
}) => {
  const [showCsvErrorModal, setShowCsvErrorModal] = useState(false);
  const [csvData, setCsvData] = useState(null);

  const csvInput = useRef(null);

  useEffect(() => {
    if (!isNil(csvData)) {
      if (csvData.size > 5242880) {
        onFail('Csv is too large to process. The max file size is 5MB.');
        return;
      }
      const reader = new FileReader();
      reader.readAsDataURL(csvData);
      reader.onload = () => {
        let formData = new FormData();
        formData.append('csv_file', csvData);
        if (!isNil(onFetch)) onFetch(formData);
        csvInput.current.value = null;
        onLoading(true);
        return fetch(path, {
          method: 'POST',
          headers: {
            'X-CSRF-Token': authenticityToken
          },
          body: formData
        })
          .then(response => {
            response.json().then(jsonResponse => {
              if (!eq(response.status, 200)) {
                onFail(jsonResponse);
              } else {
                onSuccess(jsonResponse);
              }
            });
          })
          .catch(error => {
            console.log('error ', error);
          });
      };
    }
  }, [csvData]);

  const downloadTemplate = () => {
    var encodedUri = encodeURI(importOrdersCSVTemplate);
    const link = document.getElementById('downloadCSVhiddenLink');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'Dispatch Order Entry Template.csv');
    document.body.appendChild(link);
    link.click();
  };

  const clearModal = () => {
    setShowCsvErrorModal(false);
  };

  const handleDragOver = evt => evt.preventDefault();

  const handleFile = files => {
    const fileExists = files.length > 0;
    const fileIsCsv = eq(files[0].type, 'text/csv');
    const fileIsExcel = eq(files[0].type, 'application/vnd.ms-excel');
    const fileExtensionIsCsv = eq(files[0].name.split('.').pop(), 'csv');
    const fileIsCompatibleExcel = fileIsExcel && fileExtensionIsCsv;

    if (fileExists && (fileIsCsv || fileIsCompatibleExcel)) {
      csvInput.current.files = files;
      setCsvData(files[0]);
    } else {
      setShowCsvErrorModal(true);
    }
  };

  const handleDrop = evt => {
    evt.preventDefault();
    if (!isNil(onCsvSelect)) onCsvSelect();
    handleFile(evt.dataTransfer.files);
  };

  const handleFileSelect = evt => {
    handleFile(evt.target.files);
  };

  const resetFileSelection = () => {
    onFileSelect(null);
  };

  return (
    <>
      {useFileButton && (
        <label className="block">
          <span className="sr-only">Choose File</span>
          <input
            type="file"
            onChange={handleFileSelect}
            onClick={resetFileSelection}
            className="block w-full text-gray-800
                  file:mr-4 file:py-2 file:px-4
                  file:rounded-md file:border-0
                  file:text-[15px] file:font-semibold
                  file:bg-gray-100 file:text-navy
                  hover:file:bg-gray-300"
          />
        </label>
      )}
      {!useFileButton && (
        <div className="cx_l--height-280 cx_l--flex cx_l--flex-align-center cx_l--padding-right-20 cx_l--padding-left-20 cx_l--flex-column cx_l--flex-justify-center">
          <label
            onClick={() => {
              if (onCsvSelect) onCsvSelect();
            }}
            className="cx_border--dashed cx_upload-csv"
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            htmlFor={id ?? 'csv_file'}
          >
            <div className="cx_l--flex cx_l--flex-column cx_l--flex-align-center">
              <div className="cx_i--upload cx_i cx_i--s cx_l--margin-bottom-10" />
              <span className="cx_fieldset__legend">
                {promptText ? promptText : 'Upload CSV'}
              </span>
            </div>
          </label>
        </div>
      )}
      <input
        {...props}
        id={id ?? 'csv_file'}
        className="cx_l--hidden-important"
        type="file"
        accept=".csv"
        name="csv_file"
        onChange={() => {
          if (csvInput.current.files) handleFile(csvInput.current.files);
        }}
        ref={csvInput}
      />
      {showDownloadTemplate && (
        <>
          <span
            onClick={downloadTemplate}
            role="button"
            tabIndex={0}
            onKeyDown={e => {
              if (e.key === 'Enter') downloadTemplate;
            }}
            className="cx_t--m cx_t--underline cx_t--lightBlue cx_l--margin-top-20"
          >
            Download CSV Template
          </span>
          <a id="downloadCSVhiddenLink" href="" className="hidden" />
        </>
      )}

      <Modal
        id="csv-error-modal"
        isOpen={showCsvErrorModal}
        onClose={clearModal}
      >
        <div className="p-10">
          <h3 className="cx_t cx_t--s cx_t--blue">Wrong File Format</h3>
          <div className="cx_t cx_t--xs cx_l--margin-top-32">
            The file you are attempting to upload is not in the correct format.
            Please upload a CSV file.
          </div>
          <div className="cx_l--flex cx_l--flex-align-center cx_l--flex-justify-end cx_l--margin-top-48">
            <button onClick={clearModal} className="cx_button cx_button--alpha">
              OK
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

CustomCsvUploader.defaultProps = {
  authenticityToken: null,
  onSuccess: () => {},
  onCsvSelect: () => {},
  onFail: () => {},
  onFetch: () => {},
  onLoading: () => {},
  path: '',
  showDownloadTemplate: true
};

CustomCsvUploader.propTypes = {
  authenticityToken: PropTypes.string,
  id: PropTypes.string,
  onSuccess: PropTypes.func,
  onCsvSelect: PropTypes.func,
  onFail: PropTypes.func,
  onFetch: PropTypes.func,
  onLoading: PropTypes.func,
  path: PropTypes.string,
  promptText: PropTypes.string,
  showDownloadTemplate: PropTypes.bool,
  useFileButton: PropTypes.bool,
  onFileSelect: PropTypes.func
};

CustomCsvUploader.defaultProps = {
  useFileButton: false
};

export default CustomCsvUploader;
