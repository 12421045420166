import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from '@inertiajs/inertia-react';
import Button from '../../../components/Button/Button';
import FormRow from '../../../components/FormRow/FormRow';
import Input from '../../../components/Input/Input';
import Checkbox from '../../../components/Checkbox/Checkbox';
import { useState } from 'react';

const BundleAlgorithmForm = props => {
  const { token, formData, actionName, actionUrl } = props;

  const convertToMinutesAndSeconds = seconds => {
    if (!seconds) return;
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes
      .toString()
      .padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const formatTime = timestamp => {
    if (!timestamp) return;
    const date = new Date(timestamp);
    const hours = date
      .getHours()
      .toString()
      .padStart(2, '0');
    const minutes = date
      .getMinutes()
      .toString()
      .padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  const convertDecimalToPercent = decimal => {
    if (!decimal) return;
    return decimal * 100;
  };

  const [windowThreshold, setWindowThreshold] = useState(
    convertToMinutesAndSeconds(formData?.window_threshold_seconds) ?? ''
  );
  const [durationInQueue, setDurationInQueue] = useState(
    convertToMinutesAndSeconds(formData?.duration_in_queue_seconds) ?? ''
  );
  const [driverPayMultiplier, setDriverPayMultiplier] = useState(
    convertDecimalToPercent(formData?.driver_pay_multiplier)?.toString() ?? ''
  );

  const [scheduledOrderCutoff, setScheduledOrderCutoff] = useState(
    formatTime(formData?.scheduled_order_cutoff) ?? ''
  );

  const [asapDurationInQueue, setAsapDurationInQueue] = useState(
    convertToMinutesAndSeconds(formData?.asap_duration_in_queue_seconds) ?? ''
  );

  const [durationOnAvailableTab, setDurationOnAvailableTab] = useState(
    convertToMinutesAndSeconds(formData?.duration_on_available_tab_seconds) ??
      ''
  );

  const { data, setData, submit, processing, errors } = useForm({
    authenticity_token: token,
    algorithm_params: {
      algorithm: formData?.algorithm ?? '',
      default: formData?.default ?? false,
      driver_pay_multiplier: formData?.driver_pay_multiplier ?? '',
      drop_off_distance_threshold_miles:
        formData?.drop_off_distance_threshold_miles ?? '',
      pickup_distance_threshold_miles:
        formData?.pickup_distance_threshold_miles ?? '',
      window_threshold_seconds: formData?.window_threshold_seconds ?? '',
      scheduled_order_cutoff:
        formatTime(formData?.scheduled_order_cutoff) ?? '',
      duration_in_queue_seconds: formData?.duration_in_queue_seconds ?? '',
      asap_duration_in_queue_seconds:
        formData?.asap_duration_in_queue_seconds ?? '',
      duration_on_available_tab_seconds:
        formData?.duration_on_available_tab_seconds ?? ''
    }
  });

  const firstInvalidElement = formEl => {
    // Form elements inside a form can be accessed as an array in html/js, so
    // here we iterate through the form elements until we find an invalid one and return it
    for (let el of formEl) {
      if (el.validity.valid === false) return el;
    }
    return null;
  };

  const convertToSeconds = time => {
    if (!time) return;
    const [minutes, seconds] = time.split(':');
    return parseInt(minutes) * 60 + parseInt(seconds);
  };

  const convertPercentToDecimal = percent => {
    return parseInt(percent) / 100;
  };

  const handleSubmit = e => {
    const invalidElement = firstInvalidElement(e.target);

    e.preventDefault();
    // trigger the onInvalid handler of any invalid form elements to display any error messages
    e.target.checkValidity();

    if (!invalidElement) {
      submit(actionName, actionUrl);
    }
  };

  const handleAlgorithmParamsChange = (field, val) => {
    setData({
      ...data,
      algorithm_params: { ...data.algorithm_params, [field]: val }
    });
  };

  return (
    <form className="container mx-0" onSubmit={handleSubmit}>
      <h3>Bundle Algorithms</h3>
      <div className="sm-12 text-muted">
        New Bundle Algorithms will be enabled in the selected markets by default
      </div>
      <hr className="mb-5" />
      <FormRow>
        <Input
          className="mt-5 max-w-lg"
          id="algorithm-name"
          name="algorithm-name"
          label="Name"
          value={data.algorithm_params?.algorithm || ''}
          onChange={e =>
            handleAlgorithmParamsChange('algorithm', e.target.value)
          }
          helpText="How do you want this bundle algorithm to appear"
          placeholder="Twin Cities #1"
          required={true}
          showRequiredIndicator={true}
          hasErrors={errors.algorithm}
          customErrorMessage={
            errors.algorithm
              ? errors.algorithm.join('; ')
              : 'This field is required.'
          }
          disabled={!!formData}
        />
      </FormRow>
      <FormRow>
        <Input
          className="max-w-lg"
          id="algorithm-pickup-threshold"
          name="algorithm-pickup-threshold"
          label="Distance from origin"
          value={data.algorithm_params?.pickup_distance_threshold_miles || ''}
          onChange={e =>
            handleAlgorithmParamsChange(
              'pickup_distance_threshold_miles',
              e.target.value
            )
          }
          helpText="Number of miles between pickup locations"
          placeholder="12"
          required={true}
          showRequiredIndicator={true}
          hasErrors={errors.pickup_distance_threshold_miles}
          customErrorMessage={
            errors.pickup_distance_threshold_miles
              ? errors.pickup_distance_threshold_miles.join('; ')
              : 'This field is required.'
          }
        />
      </FormRow>
      {/* <FormRow>
        <Input
          className="max-w-lg"
          id="algorithm-drop-off-threshold"
          name="algorithm-drop-off-threshold"
          label="Distance from destination"
          value={data.algorithm_params?.drop_off_distance_threshold_miles || ''}
          onChange={e =>
            handleAlgorithmParamsChange(
              'drop_off_distance_threshold_miles',
              e.target.value
            )
          }
          helpText="Number of miles between destination locations"
          placeholder="9"
          required={true}
          showRequiredIndicator={true}
          hasErrors={errors.drop_off_distance_threshold_miles}
          customErrorMessage={
            errors.drop_off_distance_threshold_miles
              ? errors.drop_off_distance_threshold_miles.join('; ')
              : 'This field is required.'
          }
        />
      </FormRow> */}
      <FormRow>
        <Input
          className="max-w-lg"
          id="algorithm-window-threshold"
          name="algorithm-window-threshold"
          label="Time between scheduled pickups"
          value={windowThreshold}
          onChange={e => {
            setWindowThreshold(e.target.value);
            handleAlgorithmParamsChange(
              'window_threshold_seconds',
              convertToSeconds(e.target.value)
            );
          }}
          helpText="Number of minutes between scheduled times (mm:ss)"
          placeholder="15:00"
          required={true}
          showRequiredIndicator={true}
          hasErrors={errors.window_threshold_seconds}
          customErrorMessage={
            errors.window_threshold_seconds
              ? errors.window_threshold_seconds.join('; ')
              : 'This field is required.'
          }
        />
      </FormRow>
      <h4>Future Orders</h4>
      <div className="sm-12 text-muted mb-5">
        Orders with a scheduled date greater than today
      </div>
      <FormRow>
        <Input
          className="max-w-lg"
          id="algorithm-future-orders-threshold"
          name="algorithm-future-orders-threshold"
          label="Release time of future orders in bundle queue"
          value={scheduledOrderCutoff}
          mask="99:99"
          onChange={e => {
            setScheduledOrderCutoff(e.target.value);
            handleAlgorithmParamsChange(
              'scheduled_order_cutoff',
              e.target.value
            );
          }}
          helpText="Release bundles for future orders out to drivers (hh::mm)"
          placeholder="15:30"
          required={false}
          showRequiredIndicator={false}
          hasErrors={errors.scheduled_order_cutoff}
          customErrorMessage={
            errors.scheduled_order_cutoff
              ? errors.scheduled_order_cutoff.join('; ')
              : 'An error was encountered.'
          }
        />
      </FormRow>
      <h4>Day of Orders</h4>
      <div className="sm-12 text-muted mb-5">Orders picking up today</div>
      <FormRow>
        <Input
          className="max-w-lg"
          id="algorithm-duration-of-order-in-bundle-que"
          name="algorithm-duration-of-order-in-bundle-que"
          label="Duration of order in bundle queue"
          value={durationInQueue}
          onChange={e => {
            setDurationInQueue(e.target.value);
            handleAlgorithmParamsChange(
              'duration_in_queue_seconds',
              convertToSeconds(e.target.value)
            );
          }}
          helpText="Set the bundle release time (mm::ss)"
          placeholder="15:00"
          required={true}
          showRequiredIndicator={true}
          hasErrors={errors.duration_in_queue_seconds}
          customErrorMessage={
            errors.duration_in_queue_seconds
              ? errors.duration_in_queue_seconds.join('; ')
              : 'This field is required.'
          }
        />
      </FormRow>
      <FormRow>
        <Input
          className="max-w-lg"
          id="algorithm-asap-duration-of-order-in-bundle-que"
          name="algorithm-asap-duration-of-order-in-bundle-que"
          label="Duration of ASAP order in bundle queue"
          value={asapDurationInQueue}
          onChange={e => {
            setAsapDurationInQueue(e.target.value);
            handleAlgorithmParamsChange(
              'asap_duration_in_queue_seconds',
              convertToSeconds(e.target.value)
            );
          }}
          helpText="Set the asap order release time (mm::ss)"
          placeholder="5:00"
          mask="99:99"
          required={false}
          showRequiredIndicator={false}
          hasErrors={errors.asap_duration_in_queue_seconds}
          customErrorMessage={errors.asap_duration_in_queue_seconds?.join('; ')}
        />
      </FormRow>
      <FormRow>
        <Input
          className="max-w-lg"
          id="algorithm-time-on-available-tab-to-all-drivers"
          name="algorithm-time-on-available-tab-to-all-drivers"
          label="Time on available tab to all drivers"
          value={durationOnAvailableTab}
          onChange={e => {
            setDurationOnAvailableTab(e.target.value);
            handleAlgorithmParamsChange(
              'duration_on_available_tab_seconds',
              convertToSeconds(e.target.value)
            );
          }}
          helpText="How long should the bundle be available after smart order matching before we separate the bundle? (mm::ss)"
          placeholder="15:00"
          mask="99:99"
          required={false}
          showRequiredIndicator={false}
          hasErrors={errors.duration_on_available_tab_seconds}
          customErrorMessage={errors.duration_on_available_tab_seconds?.join(
            '; '
          )}
        />
      </FormRow>
      <FormRow>
        <Input
          className="max-w-lg"
          id="algorithm-margin"
          name="algorithm-margin"
          label="Margin"
          value={driverPayMultiplier}
          onChange={e => {
            setDriverPayMultiplier(e.target.value);
            handleAlgorithmParamsChange(
              'driver_pay_multiplier',
              convertPercentToDecimal(e.target.value)
            );
          }}
          helpText="Enter a percentage"
          placeholder="60"
          required={true}
          showRequiredIndicator={true}
          hasErrors={errors.driver_pay_multiplier}
          customErrorMessage={
            errors.driver_pay_multiplier
              ? errors.driver_pay_multiplier.join('; ')
              : 'This field is required.'
          }
        />
      </FormRow>
      <FormRow>
        <Checkbox
          id="algorithm-default"
          label="Default algorithm for the market?"
          onChange={e => {
            handleAlgorithmParamsChange('default', e.target.checked);
          }}
          checked={data.algorithm_params?.default}
          testId="algorithm-default"
        />
      </FormRow>
      <div className="flex justify-start">
        <Button
          customClass="mr-5"
          style="primary"
          disabled={processing}
          type="submit"
        >
          Create Bundle Rule
        </Button>
        <Button style="default" type="button" href="/admin/bundle_algorithms">
          Cancel
        </Button>
      </div>
    </form>
  );
};

BundleAlgorithmForm.propTypes = {
  token: PropTypes.string,
  formData: PropTypes.objectOf(PropTypes.any),
  actionName: PropTypes.string,
  actionUrl: PropTypes.string
};
export default BundleAlgorithmForm;
