import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';
import eq from 'lodash/eq';
import map from 'lodash/map';
import filter from 'lodash/filter';
import useClickAway from '../src/connect/hooks/clickaway';

const Capabilities = ({
  code,
  title,
  options,
  initialValues,
  addOnsEnabled,
  onChange
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isMenuActive, setIsMenuActive] = useState(false);
  const menuRef = useRef();

  useEffect(() => {
    const newSelectedOptions = [];
    initialValues.forEach(capability => {
      newSelectedOptions.push(capability);
    });
    setSelectedOptions(newSelectedOptions);
  }, []);

  useClickAway(menuRef, () => {
    setIsMenuActive(false);
  });

  const isOptionSelected = option =>
    some(selectedOptions, { id: option.id, name: option.name });

  const handleMenuClick = () => {
    setIsMenuActive(!isMenuActive);
  };

  const handleOptionClick = option => {
    let newSelectedOptions = [];
    if (isOptionSelected(option)) {
      newSelectedOptions = filter(
        selectedOptions,
        selectedOption => !eq(selectedOption.id, option.id)
      );
      onChange && onChange(option, false);
    } else {
      newSelectedOptions = [
        ...selectedOptions,
        { id: option.id, name: option.name }
      ];
      onChange && onChange(option, true);
    }

    setSelectedOptions(newSelectedOptions);
  };

  const handleResetSelection = () => {
    setSelectedOptions([]);
    options.map(option => {
      onChange && onChange(option, false);
    });
  };

  return (
    <div className="flex">
      <div className="relative flex items-center border rounded-md border-gray-200 bg-white">
        <div className="flex items-center py-2">
          <div className="pl-4 pr-2">{title}:</div>
          {isEmpty(selectedOptions) ? (
            <div className="ml-2 py-1 px-2 rounded-md font-semibold text-blue bg-blue-100">
              None
            </div>
          ) : (
            map(selectedOptions, selectedOption => (
              <div
                key={selectedOption.id}
                className="ml-2 py-1 px-2 rounded-md font-semibold text-blue bg-blue-100"
              >
                {selectedOption.name}
              </div>
            ))
          )}
        </div>
        {selectedOptions.length > 0 && (
          <button
            className="h-full py-3 pl-4 pr-1"
            onClick={handleResetSelection}
            type="button"
            id="reset-selection-button"
          >
            <i className="cx_i cx_i--s cx_i--blue-close-x" />
          </button>
        )}
        <div ref={menuRef}>
          <button
            className={`h-full py-3 pr-4 'pl-1'`}
            onClick={handleMenuClick}
            type="button"
            id="menu-toggle-button"
          >
            <i
              className={`cx_i cx_i--s ${
                isMenuActive
                  ? 'cx_i--chevron-up-darkBlue'
                  : 'cx_i--chevron-down-darkBlue'
              }`}
            />
          </button>
          {isMenuActive && (
            <div
              className={`absolute bg-white z-10 border rounded-md border-gray-200 w-max min-w-[164px] right-0`}
            >
              {map(options, (option, index) => (
                <div key={option.id} className="cx_field--checkbox">
                  <input
                    className="cx_field__input"
                    type="checkbox"
                    onChange={() => handleOptionClick(option)}
                    checked={isOptionSelected(option)}
                    name={option.id}
                    id={option.id}
                  />
                  <label
                    htmlFor={option.id}
                    className={`cx_field__label py-3 px-6 cursor-pointer ${
                      eq(index, 0) ? 'pt-6' : ''
                    } ${eq(index, options.length - 1) ? 'pb-6' : ''}`}
                  >
                    {option.name}
                  </label>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      {/* Work around to get capabilities in form data on submit */}
      {addOnsEnabled ? (
        <>
          <input type="hidden" name={`driver[${code}][]`} value="" />
          {map(selectedOptions, option => (
            <input
              type="hidden"
              key={option.id}
              name={`driver[${code}][]`}
              value={option.id}
            />
          ))}
        </>
      ) : (
        map(options, option => (
          <input
            type="hidden"
            key={option.id}
            name={`driver[${code}][${option.id}]`}
            value={isOptionSelected(option)}
          />
        ))
      )}
    </div>
  );
};

Capabilities.propTypes = {
  code: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  initialValues: PropTypes.arrayOf(PropTypes.object).isRequired,
  addOnsEnabled: PropTypes.bool,
  onChange: PropTypes.func
};

export default Capabilities;
