import React from 'react';
import PropTypes from 'prop-types';
import {
  ApolloClient,
  ApolloProvider as Provider,
  createHttpLink,
  InMemoryCache,
  split
} from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink';
import createConsumer from '../../channels/consumer';

export default function ApolloProvider({ children }) {
  // Setup a link for action cable
  const actionCableLink = new ActionCableLink({ cable: createConsumer });
  const httpLink = createHttpLink({ uri: '/graphql' });

  // Redirect subscriptions to the action cable link, while using the HTTP link for other queries
  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);

      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    actionCableLink,
    httpLink
  );

  const client = new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache()
  });

  return <Provider client={client}>{children}</Provider>;
}

ApolloProvider.propTypes = {
  children: PropTypes.node.isRequired
};
