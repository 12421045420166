import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const Popover = props => {
  const {
    displayContent,
    children,
    isPopoverOpen,
    setOpenStatus,
    dropDownPosition,
    width
  } = props;
  const ref = useRef();

  useEffect(() => {
    const checkIfClickedOutside = e => {
      if (isPopoverOpen && ref.current && !ref.current.contains(e.target)) {
        setOpenStatus(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isPopoverOpen]);

  return (
    <div ref={ref}>
      <div className="absolute">
        <div
          onClick={() => setOpenStatus(true)}
          role="button"
          tabIndex={0}
          onKeyDown={() => {}}
          className="w-full text-center"
        >
          {displayContent}
        </div>

        {isPopoverOpen && (
          <div
            className="drop-shadow-2xl absolute bg-white overflow-scroll mx-4 z-10"
            style={{
              maxHeight: '400px',
              width: width || '300px',
              right: dropDownPosition === 'left' ? '100%' : null,
              left: dropDownPosition === 'right' ? '100%' : null
            }}
          >
            {children}
          </div>
        )}
      </div>
    </div>
  );
};

Popover.defaultProps = {
  setOpenStatus: () => {}
};

Popover.propTypes = {
  displayContent: PropTypes.element,
  children: PropTypes.element,
  isPopoverOpen: PropTypes.bool,
  setOpenStatus: PropTypes.func,
  dropDownPosition: PropTypes.string,
  width: PropTypes.string
};

export default Popover;
